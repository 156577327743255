<template>
  <div class="">
    <h3 class="ml40">Сэтгэгдэл</h3>
    <el-row :gutter="20" v-loading='loading'>
      <el-col :span="22" :offset="2">
        <el-input ref="inputRef" placeholder="Сэтгэгдэл бичих" v-model="comment">
          <el-button :disabled="this.comment.length > 0 ? isDisabled = false : isDisabled = true" type="warning" icon="el-icon-circle-check" slot="append" @click="sendComment">Илгээх</el-button>
        </el-input>
      </el-col>
    </el-row>
    <el-timeline class="mt20" v-if="comments.length > 0">
      <el-timeline-item type="warning" placement="top" v-for="(comment, index) in comments" :key="index" :timestamp="comment.created_at">
        <el-card>
          <h4 class="mt0">{{ comment.user_name }}</h4>
          <div class="mb10">
            <el-row :gutter="20">
              <el-col :span="24">
                {{ comment.comment }}
              </el-col>
            </el-row>
          </div>
        </el-card>
      </el-timeline-item>
    </el-timeline>
  </div>
</template>
<script>
import { getUserName } from '../../../../utils/auth.js'
import services from '../../../../helpers/services'
export default {
  name: 'comment',

  props: [
    'comments',
    'transfer_id'
  ],

  data () {
    return {
      comment: '',
      loading: false,
      isDisabled: true
    }
  },

  methods: {
    async sendComment () {
      this.loading = true
      const sendBody = {
        type: 'comment',
        user_name: '',
        transfer_id: this.transfer_id,
        comment: this.comment
      }
      sendBody.user_name = await getUserName()
      services.changeTransferStatus(sendBody).then(response => {
        if (response.status === 'success') {
          this.loading = false
          this.$refs.inputRef.clear()
          this.comments.push(sendBody)
        }
      })
    }
  }
}
</script>
