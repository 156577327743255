<template>
  <div>
    <!-- Finish transfer dialog -->
    <el-dialog
      title="Татан авалтыг дуусгах"
      :visible.sync="show"
      @close="closeFinishDialog"
      width="700px"
      v-loading="loading">
      <p>There is incoming inventory that needs to be processed before this transfer can be completed.</p>
      <el-row :gutter="80" type="flex" align="middle">
        <el-col :span="14" class="border-right">
          <el-radio-group v-model="finishType" @change="changeFiniType">
            <div class="mtb20">
              <el-radio label="accepted">
                Ирсэн
                <template>
                  <p class="text-color-secondary ml30 mt0">Inventory will be available for sale (visibility will not change).</p>
                </template>
              </el-radio>
            </div>
            <div class="mtb20">
              <el-radio label="canceled">
                Буцаагдсан
                <template>
                  <p class="text-color-secondary ml30 mt0">These items will never arrive.</p>
                </template>
              </el-radio>
            </div>
            <div class="mtb20">
              <el-radio label="rejected">
                Гологдол
                <template>
                  <p class="text-color-secondary ml30 mt0">These items are damaged or not sellable.</p>
                </template>
              </el-radio>
            </div>
          </el-radio-group>
        </el-col>
        <el-col :span="10">
          <div class="text-center">
            <progress-bar
            :accepted="localAccepted"
            :canceled="localCanceled"
            :rejected="localRejected"
            :total="transfer.number_tobe_transferred"
            ></progress-bar>
          </div>
        </el-col>
      </el-row>
      <span slot="footer" class="dialog-footer">
        <el-button @click="show = false">Болих</el-button>
        <el-button type="success" @click="saveTransfer">Татан авалтыг дуусгах</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import services from '../../../../helpers/services'
import ProgressBar from './progressBar.vue'
import { getUserName } from '../../../../utils/auth.js'
export default {
  name: 'finishTransferDialog',
  props: [
    'visibleFinishTransfer',
    'transfer'
  ],
  components: {
    ProgressBar
  },
  data () {
    return {
      finishType: '',
      show: this.visibleFinishTransfer,
      localAccepted: this.transfer.accepted,
      localCanceled: this.transfer.canceled,
      localRejected: this.transfer.rejected,
      loading: false
    }
  },
  methods: {
    closeFinishDialog () {
      this.$emit('update:visibleFinishTransfer', false)
    },
    changeFiniType (val) {
      switch (val) {
        case 'accepted':
          this.localAccepted = (this.transfer.number_tobe_transferred + this.transfer.accepted)
          this.localCanceled = this.transfer.canceled
          this.localRejected = this.transfer.rejected
          break
        case 'canceled':
          this.localAccepted = this.transfer.accepted
          this.localCanceled = (this.transfer.number_tobe_transferred + this.transfer.canceled)
          this.localRejected = this.transfer.rejected
          break
        case 'rejected':
          this.localAccepted = this.transfer.accepted
          this.localCanceled = this.transfer.canceled
          this.localRejected = (this.transfer.number_tobe_transferred + this.transfer.rejected)
          break
        default:
          break
      }
    },
    alertReporter (title, message, type) {
      this.$notify({
        title: title,
        message: message,
        type: type,
        position: 'top-right'
      })
    },
    async saveTransfer () {
      if (this.finishType === 'accepted' || this.finishType === 'canceled' || this.finishType === 'rejected') {
        const sendBody = {
          type: 'log',
          transfer_id: this.transfer.transfer_id,
          user_name: 'gal',
          products: []
        }
        sendBody.user_name = await getUserName()
        this.transfer.products.forEach(product => {
          product.variants.forEach(variant => {
            let body = null
            switch (this.finishType) {
              case 'accepted':
                body = {
                  accepted: parseInt(variant.number_tobe_transferred + variant.accepted),
                  canceled: variant.canceled,
                  rejected: variant.rejected,
                  product_id: variant.product_id,
                  variant_id: variant.variant_id
                }
                break
              case 'canceled':
                body = {
                  accepted: variant.accepted,
                  canceled: parseInt(variant.number_tobe_transferred + variant.canceled),
                  rejected: variant.rejected,
                  product_id: variant.product_id,
                  variant_id: variant.variant_id
                }
                break
              case 'rejected':
                body = {
                  accepted: variant.accepted,
                  canceled: variant.canceled,
                  rejected: parseInt(variant.number_tobe_transferred + variant.rejected),
                  product_id: variant.product_id,
                  variant_id: variant.variant_id
                }
                break
              default:
                break
            }
            sendBody.products.push(body)
          })
        })
        this.loading = true
        services.changeTransferStatus(sendBody).then(data => {
          if (data.status === 'success') {
            this.loading = false
            this.alertReporter('Амжилттай', data.message, 'success')
            this.$router.push({ name: 'transfer' })
          } else if (data.status === 'error') {
            this.alertReporter('Алдаа', data.message, 'error')
            this.loading = false
          }
        })
      } else {
        this.alertReporter('Анхааруулга', 'Татан авалтыг дуусгах төрлийг сонгоно уу', 'warning')
      }
    }
  }
}
</script>
